.welcome-container {
  text-align: center;
  margin-top: 50px;
}

.welcome-heading {
  font-size: 36px;
  color: #333;
}

.welcome-text {
  font-size: 18px;
  color: #666;
}
